import { logger } from '@hatchd/utils';
import {
  PaymentIntent,
  SetupIntent,
  Stripe,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js';
import { toast } from 'react-toastify';

/** Given a clientSecret, either confirm payment, or confirm card setup (free trial period) */
export async function handleSubscriptionPayment({
  stripe,
  isTrial,
  clientSecret,
  cardNumberElement,
  billingName,
}: {
  stripe: Stripe;
  isTrial: boolean;
  clientSecret: string;
  cardNumberElement: StripeCardNumberElement;
  billingName?: string;
}) {
  let error: StripeError | undefined;
  let intent: PaymentIntent | SetupIntent | undefined;

  // Not in a trial period, confirm the card payment
  if (!isTrial) {
    logger.info('Plan is to be paid, confirming card payment');

    try {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: billingName,
          },
        },
      });

      error = result.error;
      intent = result.paymentIntent;
    } catch (error: any) {
      logger.error(error);
      toast.error(
        `Error confirming card payment: ${error.message}. Please contact Agora support.`
      );
    }
  }

  // In a trial period, first confirm the card setup and then update the payment method
  if (isTrial) {
    logger.info('Plan is free trial, confirming card setup');

    try {
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: billingName,
          },
        },
      });

      error = result.error;
      intent = result.setupIntent;
    } catch (error) {
      logger.error(error);
      toast.error('Error confirming card setup');
    }
  }
  return { error, intent };
}
